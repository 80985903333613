import 'plyr-react/dist/plyr.css'

import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import Plyr, { APITypes } from 'plyr-react'
import React, { memo, useEffect, useRef } from 'react'

export interface Props {
  description?: string
  ID: string
}

export const Video = memo(function Video({ description, ID }: Props) {
  if (!ID) {
    return null
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr')
      Array.from(document.querySelectorAll('.video')).map((p) => new Plyr(p))
    }
  }, [])

  return (
    <Container>
      <IconLogo />

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Line className="separator" />

      <Wrapper>
        <Player
          className="video"
          data-plyr-provider="youtube"
          data-plyr-embed-id={ID}
        />

        {/*  <Plyr
          ref={ref}
          source={{
            type: 'video',
            sources: [
              {
                src: ID,
                provider: 'youtube',
              },
            ],
          }}
          options={videoOptions}
        /> */}

        {/* <Player
          src={`https://www.youtube.com/embed/${ID}?fs=0&modestbranding=1&rel=0&showinfo=0`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        /> */}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.875rem 0 13.75rem;
  padding: 6.25rem 10.556vw 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0;
    bottom: 35.5vh;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: auto;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  .separator {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin: 5.625rem 0 9.375rem;
    padding: 5rem 1.875rem 0;
    &:before {
      bottom: 30vh;
    }
  }

  @media (max-width: 1023px) {
    margin: -3.75rem 0 7.5rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    &:before {
      bottom: 7.5rem;
    }
  }
`

const Description = styled.div`
  max-width: 55rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: 0 auto 1.875rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`

const Wrapper = styled.div`
  height: 71.1vh;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin-top: 3.75rem;
  overflow: hidden;
  position: relative;

  .plyr {
    height: 100%;
    --plyr-font-family: ${({ theme }) => theme.fontFamily.paragraph};
    --plyr-color-main: ${({ theme }) => theme.colors.variants.secondaryLight};

    .plyr__poster {
      background-size: cover;
    }

    .plyr__video-wrapper {
      width: 120%;
      margin-left: -10%;
    }
  }

  @media (max-width: 1199px) {
    height: 60vh;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }

  @media (max-width: 767px) {
    height: 16.25rem;
  }
`

const Player = styled.div``
